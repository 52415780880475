<template>
  <div class="myDiscussionList">
    <div class="section-box">
      <div class="section-new-discussion">
        <div class="discussion-content">
          <v-list
            :finished="finished"
            @onLoad="onLoad"
            ref="list"
            :finishedText="finishedText"
          >
            <div
              class="discussion-content-item"
              v-for="(item, index) in listData"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="content-item--text">{{ item.title }}</div>
              <div class="content-item--date">{{ item.insertTime }}</div>
              <div class="content-item--detail">{{ item.content }}</div>
              <div class="content-item--operate" @click.stop>
                <div class="operate-user">
                  <div class="operate-user--picture">
                    <img
                      :src="
                        item.isAnonymity == 1 || !item.avatar
                          ? require('./img/default_avatar.png')
                          : item.avatar
                      "
                      alt=""
                    />
                  </div>
                  <div class="operate-user--name">
                    {{ item.userName }}
                  </div>
                </div>
                <div class="operate-button">
                  <div
                    class="operate-button--status"
                    :style="item.status | btnCss"
                  >
                    {{ item.status | btnTxt }}
                  </div>
                </div>
              </div>
              <div class="content-item--audio" v-show="item.status == 3">
                <span>审核失败原因：</span>
                <span>{{ item.audioTxt }}</span>
              </div>
            </div>
          </v-list>
          <!-- <v-empty
            v-show="discussionList && discussionList.length == 0"
            description="暂无数据"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { getMyPropositionListURL } from "./api";
import { discussionStatusMap } from "./map";

export default {
  name: "myDiscussionList",
  data() {
    return {
      /* v-list 通用 */
      // isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [
        // {
        //   title: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   content:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   insertTime: "2023-2-28 10:30:47",
        //   picture: "",
        //   userName: "刘月",
        //   isAnonymity: 1, // 是否匿名
        //   isChosen: 2, // 0未选择 1支持 2反对
        //   readNum: 109,
        //   publicSupport: 41,
        //   publicOppose: 1,
        //   propositionPersons: 42,
        //   status: 0,
        // },
        // {
        //   title: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   content:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   insertTime: "2023-2-28 10:30:47",
        //   picture: "",
        //   userName: "刘月",
        //   isAnonymity: 0, // 是否匿名
        //   isChosen: 1, // 0未选择 1支持 2反对
        //   readNum: 109,
        //   publicSupport: 41,
        //   publicOppose: 1,
        //   propositionPersons: 42,
        //   status: 1,
        // },
        // {
        //   title: "有的一楼业主私占绿地种菜，就在二期大门口对面， 难道没人管吗",
        //   content:
        //     "这些都是属于我们的公摊面积，自己小院改成水泥地， 影响绿化不说，现在居然扩大面积种菜，真让人费fdsafasfsdafad",
        //   insertTime: "2023-2-28 10:30:47",
        //   picture: "",
        //   userName: "刘月",
        //   isAnonymity: 0, // 是否匿名
        //   isChosen: 0, // 0未选择 1支持 2反对
        //   readNum: 109,
        //   publicSupport: 41,
        //   publicOppose: 1,
        //   propositionPersons: 42,
        //   status: 3,
        //   audioTxt: "议事内容涉及居民隐私，不支持发表议事",
        // },
      ], // 列表数据集合
      curPage: 1,
      pageSize: 20,
      /** end */
    };
  },

  filters: {
    btnTxt(status) {
      return discussionStatusMap[status];
    },
    btnCss(status) {
      let discussionStatusCSS = {
        0: {
          color: "rgba(169, 169, 169, 0.85)",
          background: "#EAEAEA",
        },
        1: {
          color: "#DA9797",
          background: "#FCF2F3",
        },
        2: {
          color: "#FFFFFF",
          background: "#D93747",
        },
      };
      return discussionStatusCSS[status];
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    // 点击标题跳转议题详情页
    toDetail(item) {
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/xubPages/dicussionDetail/index?params=${JSON.stringify(
            params
          )}`,
        });
      }
    },

    /* 接口 */
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getMyPropositionListURL, {
          params: {
            // tenantId: this.tenantId,
            // houseId: this.houseId || this.communityId || undefined,
            userId: this.userId,
            // status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            // topicType: 31, // 31 红色引领
            curPage: this.curPage,
            pageSize: this.pageSize,
            // sortField: "NEW", // 排序方式， 最热 or 最新
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              this.listData = [];
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /* 辅助方法 */
  },
};
</script>

<style lang="less" scoped>
// 多行省略
.dhsl {
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
// 单行省略
.dhsl-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.myDiscussionList {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  min-height: 100%;
  background: #f6f8fb;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-box {
    padding: 36px 30px;
    .section-new-discussion {
      .discussion-content {
        // padding: 34px 24px 0;
        border-radius: 0 0 16px 16px;
        .discussion-content-item {
          border-radius: 16px;
          padding: 34px 24px 0;
          background: #ffffff;
          &:not(:first-child) {
            margin-top: 44px;
          }
          // &:not(:last-child) {
          //   border-bottom: 2px solid #f3f3f3;
          // }
          .content-item--text {
            font-size: 28px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
            line-height: 40px;
            &:extend(.dhsl);
          }
          .content-item--date {
            font-size: 24px;
            color: #c8c8c8;
            line-height: 34px;
            height: 34px;
            margin: 14px 0;
          }
          .content-item--detail {
            &:extend(.dhsl);
            font-size: 26px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 36px;
          }
          .content-item--operate {
            padding: 34px 0 40px;
            display: flex;
            align-items: center;
            .operate-user {
              display: flex;
              align-items: center;
              .operate-user--picture {
                width: 58px;
                height: 58px;
                border-radius: 50%;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  // vertical-align: middle;
                  margin: 0;
                  padding: 0;
                  overflow: hidden;
                  object-fit: cover;
                }
              }
              .operate-user--name {
                font-size: 24px;
                color: #333333;
                line-height: 34px;
                max-width: 250px;
                &:extend(.dhsl);
                margin-left: 24px;
              }
            }
            .operate-button {
              flex: 1;
              display: flex;
              justify-content: flex-end;
              .operate-button--status {
                margin: 0 10px;
                border-radius: 8px;
                white-space: nowrap;
                font-size: 24px;
                line-height: 34px;
                padding: 6px 26px;
              }
            }
          }
          .content-item--audio {
            font-size: 24px;
            padding: 18px 10px;
            color: #d93747;
            line-height: 34px;
            border-top: 2px solid #f0f0f0;
          }
        }
      }
    }
  }
}
</style>
