import { mapHelper } from "@/utils/utils.js";

// 投票状态
const voteStatus = [
  {
    text: "待审核", // 先业委会半数同意-填写投票时间、后社区同意 点不了
    value: 0,
  },
  {
    text: "审核通过",
    value: 1,
  },
  {
    text: "审核未通过",
    value: 2,
  },
  {
    text: "公示中",
    value: 3,
  },
  {
    text: "待投票",
    value: 4,
  },
  {
    text: "投票中",
    value: 5,
  },
  {
    text: "已计票", // 时间到
    value: 6,
  },
  {
    text: "唱票复核中", // 结束第二天开始，业主到社区电脑端申请复核查看
    value: 7,
  },
  {
    text: "公示投票结果",
    value: 8,
  },
];

const { map: voteStatusMap, setOps: setVoteStatusOps } =
  mapHelper.setMap(voteStatus);

// 议题状态
const discussionStatus = [
  {
    text: "待审核",
    value: 0,
  },
  {
    text: "议题中",
    value: 1,
  },
  {
    text: "审核失败",
    value: 2,
  },
];

const { map: discussionStatusMap, setOps: setDiscussionStatusOps } =
  mapHelper.setMap(discussionStatus);

// 投票类型
const voteType = [
  {
    text: "制定和修改业主大会议事规则",
    value: 1,
  },
  {
    text: "制定和修改管理规约",
    value: 2,
  },
  {
    text: "选举业主委员会或者更换业主委员会成员",
    value: 3,
  },
  {
    text: "选聘和解聘物业服务企业或者其他管理人",
    value: 4,
  },
  {
    text: "使用建筑物及其附属设施的维修资金",
    value: 5,
  },
  {
    text: "筹集建筑物及其附属设施的维修资金",
    value: 6,
  },
  {
    text: "改建、重建建筑物及其附属设施",
    value: 7,
  },
  {
    text: "改变共有部分的用途或者利用共有部分从事经营活动",
    value: 8,
  },
  {
    text: "有关共有和共同管理权利的其他重大事项",
    value: 9,
  },
];

const { map: voteTypeMap, setOps: setVoteTypeOps } = mapHelper.setMap(voteType);

export {
  voteStatusMap,
  setVoteStatusOps,
  discussionStatusMap,
  setDiscussionStatusOps,
  voteTypeMap,
  setVoteTypeOps,
};
